import * as React from "react";

function SvgComponent(props) {
  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 937 171">
      <path
        id="XMLID_8_"
        d="M301.2,14.7l9.9-9.9l1.7,1.7l-9.9,9.9L301.2,14.7z"
      />
      <g id="XMLID_1_">
        <path
          id="XMLID_2_"
          d="M11,135.5c7.9,0.2,11.3,4.2,11.3,10.7c0,6.8-3.6,11-11.4,11H4.6v-21.7C5,135.5,9.2,135.5,11,135.5z M9,153.7
		h2c3.7,0,6.7-2,6.7-7.5c0-5.1-2.6-7.2-6.7-7.2H9V153.7z"
        />
        <path
          id="XMLID_5_"
          d="M30.4,139.5h-4.3v-4h4.3V139.5z M30.4,157.2h-4.2v-15.5h4.2V157.2z"
        />
        <path
          id="XMLID_9_"
          d="M34.8,141.7H39v3.7c0.7-2.4,2.5-4,4.9-4c0.3,0,0.6,0,0.7,0.1v4.2c-0.2-0.1-0.4-0.1-0.7-0.1
		c-3.4-0.3-4.8,1.1-4.8,3.2v8.5h-4.4V141.7z"
        />
        <path
          id="XMLID_13_"
          d="M54.6,154.3c1.5,0,2.7-0.6,3.2-2.1h3.9c-0.5,3.3-3.8,5.2-7,5.2c-4.6,0-7.8-3.1-7.8-7.9
		c0-4.7,2.8-8.2,7.5-8.2c4.8,0,7.2,3.2,7.2,7.9v1.2H51.3C51.3,152.8,52.5,154.3,54.6,154.3z M57.7,147.8c0-1.9-1-3.4-3.2-3.4
		c-2,0-3.2,1.7-3.2,3.4H57.7z"
        />
        <path
          id="XMLID_38_"
          d="M78.7,147.4h-3.7c-0.2-1.2-1.1-2.6-2.9-2.6c-2,0-3.4,1.6-3.4,4.8c0,3,1.3,4.9,3.5,4.9
		c1.8,0,2.6-1.5,2.8-2.6h3.6c-0.2,3.2-2.7,5.7-6.5,5.7c-4.3,0-7.5-3-7.5-8c0-4.8,3-8.1,7.5-8.1C76.1,141.4,78.5,144,78.7,147.4z"
        />
        <path
          id="XMLID_43_"
          d="M87.8,144.7l0,8.3c0,1.1,0.4,1.3,1.4,1.3h2.1v2.7c-0.4,0.2-1.2,0.4-2.7,0.4c-4.1,0-5.2-1.7-5.2-4.2v-8.5
		h-2.5v-3h2.7l1.1-4.7h3.1v4.7h3.5v3.1L87.8,144.7L87.8,144.7z"
        />
        <path
          id="XMLID_46_"
          d="M93.7,149.5c0-4.9,2.9-8.1,7.5-8.1c4.6,0,7.5,3.2,7.5,8.1c0,4.7-2.8,8-7.5,8
		C96.6,157.5,93.7,154.4,93.7,149.5z M104.4,149.4c0-2.5-0.8-4.8-3.2-4.8c-2.3,0-3.2,2.1-3.2,4.8c0,2.4,0.8,4.9,3.2,4.9
		C103.5,154.2,104.4,152,104.4,149.4z"
        />
        <path
          id="XMLID_55_"
          d="M112.2,141.7h4.2v3.7c0.7-2.4,2.5-4,4.9-4c0.3,0,0.6,0,0.7,0.1v4.2c-0.2-0.1-0.4-0.1-0.7-0.1
		c-3.4-0.3-4.8,1.1-4.8,3.2v8.5h-4.4L112.2,141.7L112.2,141.7z"
        />
        <path
          id="XMLID_57_"
          d="M131.5,149.5c0-4.9,2.9-8.1,7.5-8.1c4.6,0,7.5,3.2,7.5,8.1c0,4.7-2.8,8-7.5,8
		C134.4,157.5,131.5,154.4,131.5,149.5z M142.2,149.4c0-2.5-0.8-4.8-3.2-4.8c-2.3,0-3.2,2.1-3.2,4.8c0,2.4,0.8,4.9,3.2,4.9
		C141.3,154.2,142.2,152,142.2,149.4z"
        />
        <path
          id="XMLID_60_"
          d="M159,144.7h-3.3v12.5h-4.4v-12.5h-2.9v-3h2.9v-2.2c0-2.5,1.6-4,4.2-4h3.6l0,2.9h-2.1
		c-1.1,0-1.5,0.5-1.5,1.8v1.5h3.4L159,144.7L159,144.7z"
        />
        <path
          id="XMLID_62_"
          d="M169.8,135.5h8c4.7,0,8.3,2.5,8.3,7.1c0,4.3-3.1,6.9-7.7,6.9h-4.1v7.8h-4.4L169.8,135.5L169.8,135.5z
		 M178.2,146.1c2.2,0,3.8-1.6,3.8-3.7c0-2.2-1.7-3.5-3.8-3.5h-4v7.2H178.2z"
        />
        <path
          id="XMLID_65_"
          d="M189.4,134.9h4.4v9c0.8-1.1,2-2.3,4.5-2.3c3,0,5.4,1.8,5.4,4.9v10.7h-4.4v-10c0-1.4-1.2-2.2-2.6-2.2
		c-1.4,0-2.9,0.8-2.9,2.3v9.9h-4.4V134.9z"
        />
        <path
          id="XMLID_67_"
          d="M206.9,149.5c0-4.9,2.9-8.1,7.5-8.1c4.6,0,7.5,3.2,7.5,8.1c0,4.7-2.8,8-7.5,8
		C209.9,157.5,206.9,154.4,206.9,149.5z M217.7,149.4c0-2.5-0.8-4.8-3.2-4.8c-2.3,0-3.2,2.1-3.2,4.8c0,2.4,0.8,4.9,3.2,4.9
		C216.8,154.2,217.7,152,217.7,149.4z"
        />
        <path
          id="XMLID_70_"
          d="M230.9,144.7l0,8.3c0,1.1,0.4,1.3,1.4,1.3h2.1v2.7c-0.4,0.2-1.2,0.4-2.7,0.4c-4.1,0-5.1-1.7-5.1-4.2v-8.5
		H224v-3h2.7l1.1-4.7h3.1v4.7h3.5v3.1L230.9,144.7L230.9,144.7z"
        />
        <path
          id="XMLID_72_"
          d="M236.8,149.5c0-4.9,2.9-8.1,7.5-8.1c4.6,0,7.5,3.2,7.5,8.1c0,4.7-2.8,8-7.5,8
		C239.7,157.5,236.8,154.4,236.8,149.5z M247.5,149.4c0-2.5-0.8-4.8-3.2-4.8c-2.3,0-3.2,2.1-3.2,4.8c0,2.4,0.8,4.9,3.2,4.9
		C246.6,154.2,247.5,152,247.5,149.4z"
        />
        <path
          id="XMLID_75_"
          d="M253.8,158.5c0-2.1,2.7-2.8,3-2.9c-0.5-0.3-1.9-0.8-1.9-2.3c0-1.1,1-2.1,3.2-2.5c-2.1-1-3.1-2.8-3.1-4.5
		c0-3.1,3.1-4.9,7.4-4.9c2.1,0,3.4,0.5,4.5,1.2c0.9-0.4,3.3-1.3,4.6-1.8l0,3.4l-2.7,0.4c0.2,0.6,0.3,1.2,0.3,1.6
		c0,2.7-2.4,5.1-7,5.1c-0.4,0-1.1,0-1.5-0.1c-1.2,0.1-1.5,0.3-1.5,0.6c0,0.5,0.9,0.6,2.7,0.7c0.7,0,1.9,0.1,3.2,0.2
		c3.8,0.2,5.7,2,5.7,4.4c0,2.9-2.8,5-8.3,5S253.8,160.8,253.8,158.5z M266.4,158.2c0-0.9-0.8-1.6-2.4-1.7l-3.6-0.2
		c-1.4-0.1-2.3,0.8-2.3,1.8c0,1.3,1.5,1.9,4.9,1.9C265.4,159.9,266.4,159.3,266.4,158.2z M264.9,146.5c0-1.6-1.1-2.7-2.8-2.7
		c-1.7,0-2.8,1.1-2.8,2.7c0,1.5,1,2.5,2.8,2.5C263.8,149,264.9,148,264.9,146.5z"
        />
        <path
          id="XMLID_79_"
          d="M274.5,141.7h4.2v3.7c0.7-2.4,2.5-4,4.9-4c0.3,0,0.6,0,0.7,0.1v4.2c-0.2-0.1-0.5-0.1-0.7-0.1
		c-3.5-0.3-4.8,1.1-4.8,3.2v8.5h-4.4V141.7z"
        />
        <path
          id="XMLID_81_"
          d="M286.4,152.8c0-3.4,2.5-5.2,7.9-5.2l2,0v-0.9c0-1.4-0.8-2.2-2.6-2.2c-1.3,0-2.6,0.5-3,2h-3.6
		c0.2-3.4,3-4.9,6.9-4.9c4.7,0,6.6,1.9,6.6,4.9v10.9h-3.7l-0.4-2.7c-1.1,2.2-2.6,3-4.8,3C288.7,157.5,286.4,155.8,286.4,152.8z
		 M296.3,152v-2.3l-1.6,0c-2.2,0-4.1,0.7-4.1,2.6c0,1.4,1.1,2.3,2.6,2.3C294.7,154.6,296.3,153.4,296.3,152z"
        />
        <path
          id="XMLID_84_"
          d="M304.6,141.7h4.4l0,2.2c1-1.4,2.7-2.5,4.8-2.5c3.5,0,6,2.5,6,7.9c0,5.6-2.7,8.2-6.4,8.2
		c-2,0-3.6-1.2-4.4-2.6v7.2h-4.4V141.7z M315.7,149.4c0-3-1.4-4.8-3.4-4.8c-2.3,0-3.4,2.1-3.4,4.8c0,2.6,1.1,4.9,3.4,4.9
		C314.2,154.3,315.7,152.8,315.7,149.4z"
        />
        <path
          id="XMLID_88_"
          d="M323.3,134.9h4.4v9c0.8-1.1,2-2.3,4.5-2.3c3,0,5.4,1.8,5.4,4.9v10.7h-4.4v-10c0-1.4-1.2-2.2-2.6-2.2
		c-1.4,0-2.9,0.8-2.9,2.3v9.9h-4.4V134.9z"
        />
        <path
          id="XMLID_90_"
          d="M340.6,161.6v-2.8l1.5,0.1c2.7,0.2,3-1,3.2-1.5l0.3-0.9l-6-14.9h4.3l3.7,10.1l3.8-10.1h4.3l-6.3,15.6
		c-1.6,4-3.5,4.9-5.9,4.9C341.4,162.2,340.6,161.6,340.6,161.6z"
        />
      </g>
      <path
        id="XMLID_17_"
        d="M59.6,114.2l44.2-94.6h2.6l44.5,94.6h-2.8l-14.4-30.4H76.5l-14.1,30.4H59.6z M77.7,81.3h54.8l-27.4-58.8
	L77.7,81.3z"
      />
      <path
        id="XMLID_20_"
        d="M213.6,19.6V22h-36.2v92.2H175V22h-36.1v-2.4H213.6z"
      />
      <path id="XMLID_26_" d="M305.9,114.2V19.6h2.4v94.6H305.9z" />
      <path
        id="XMLID_28_"
        d="M408,100.4c-8.6,8.6-20.4,13.9-33.6,13.9c-26.1,0-47.3-21.2-47.3-47.3c0-26.1,21.2-47.3,47.3-47.3
	c13,0,24.7,5.2,33.3,13.6l-1.8,1.6c-8.1-7.9-19.2-12.9-31.5-12.9c-24.9,0-44.9,20.1-44.9,44.9s20.1,44.9,44.9,44.9
	c12.4,0,23.7-5.2,31.8-13.4L408,100.4z"
      />
      <path id="XMLID_30_" d="M423.5,114.2V19.6h2.4v94.6H423.5z" />
      <path
        id="XMLID_32_"
        d="M439.5,114.2l44.2-94.6h2.7l44.5,94.6h-2.8l-14.4-30.4h-57.3l-14.1,30.4H439.5z M457.7,81.3h54.8l-27.4-58.8
	L457.7,81.3z"
      />
      <path
        id="XMLID_35_"
        d="M577.2,111.8h56.7v2.4h-59V19.6h2.4L577.2,111.8L577.2,111.8z"
      />
      <path
        id="XMLID_39_"
        d="M673.5,19.6c26.2,0,47.4,21.1,47.4,47.3s-21.2,47.3-47.4,47.3c-26.2,0-47.3-21.1-47.3-47.3
	S647.3,19.6,673.5,19.6z M673.5,111.8c25,0,45-19.9,45-44.9S698.5,22,673.5,22s-44.9,19.9-44.9,44.9S648.6,111.8,673.5,111.8z"
      />
      <path
        id="XMLID_47_"
        d="M929.4,44c0-12.1-9.7-21.8-21.8-21.8c-12.1,0-21.8,9.7-21.8,21.8c0,12.1,9.7,21.8,21.8,21.8
	c13.4,0,24.2,10.9,24.2,24.2c0,13.4-10.8,24.2-24.2,24.2s-24.2-11-24.2-24.4h2.4c0,12.1,9.7,22,21.8,22c12.1,0,21.8-9.7,21.8-21.8
	s-9.7-21.9-21.8-21.9c-13.4,0-24.2-10.9-24.2-24.2s10.8-24.2,24.2-24.2s24.2,11,24.2,24.4H929.4z"
      />
      <g id="XMLID_11_">
        <path
          id="XMLID_12_"
          fill="none"
          d="M38.3,73.1C52.1,73,63.6,61.8,63.6,47.6C63.6,33.5,52.1,22,38,22H13.5v51.1L38.3,73.1
		L38.3,73.1z"
        />
        <path
          id="XMLID_49_"
          d="M38.3,19.6H6.3V22h2.4h4.8H38c14.1,0,25.6,11.5,25.6,25.6S52.1,73,38.3,73.1H13.5H8.7H6.3v41.1h2.4V75.5
		h29.5c15.4,0,28-12.5,28-27.9S53.7,19.6,38.3,19.6z"
        />
      </g>
      <g id="XMLID_14_">
        <path
          id="XMLID_15_"
          fill="none"
          d="M258.4,73.1c13.9-0.1,25.4-11.4,25.4-25.5c0-14-11.4-25.5-25.4-25.6h-26.2v51.1L258.4,73.1
		L258.4,73.1z"
        />
        <path
          id="XMLID_16_"
          d="M286.4,47.6c0-15.4-12.6-28-28-28h-31.9V22h2.4h3.3h26.2c14,0.1,25.4,11.6,25.4,25.6
		c0,14.1-11.5,25.4-25.4,25.5h-26.2h-3.3h-2.4v41.1h2.4V75.5h27l22.3,38.7h2.9l-22.3-38.7C274,75.3,286.4,62.9,286.4,47.6z"
        />
      </g>
      <g id="XMLID_25_">
        <path
          id="XMLID_50_"
          fill="none"
          d="M767.8,73.1c13.9-0.1,25.4-11.4,25.4-25.5c0-14.1-11.5-25.6-25.6-25.6h-26.1v51.1L767.8,73.1
		L767.8,73.1z"
        />
        <path
          id="XMLID_51_"
          d="M767.8,19.6h-31.9V22h2.4h3.2h26.1c14.1,0,25.6,11.5,25.6,25.6S781.7,73,767.8,73.1h-26.4h-3.2h-2.4v41.1
		h2.4V75.5h29.5c15.4,0,28-12.5,28-27.9S783.2,19.6,767.8,19.6z"
        />
      </g>
      <polygon
        id="XMLID_44_"
        points="815.8,22.1 870.1,22.1 870.1,19.6 811.2,19.6 811.2,22.1 813.6,22.1 "
      />
      <polygon
        id="XMLID_52_"
        points="809.2,114.2 868.1,114.2 868.1,111.7 811.6,111.7 811.6,111.7 809.2,111.7 "
      />
      <polygon
        id="XMLID_24_"
        points="815.8,70.2 870.1,70.2 870.1,67.7 811.2,67.7 811.2,70.2 813.6,70.2 "
      />
    </svg>
  );
}

export default SvgComponent;
